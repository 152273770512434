import { Locales } from '@/i18n/locales'

import en from '@/locales/en.json'
import de from '@/locales/de.json'
import { createI18n } from 'vue-i18n'

export const messages = {
  [Locales.EN]: en,
  [Locales.DE]: de
}

export let defaultLocale: string

const currentSelectedLanguage = localStorage.getItem('currentSelectedLanguage')

if (currentSelectedLanguage === null) {
  defaultLocale = navigator.language.split('-')[0]
} else {
  defaultLocale = currentSelectedLanguage
}

export const fallbackLocale = Locales.DE

export const i18n = createI18n({
  messages,
  locale: defaultLocale,
  warnHtmlInMessage: 'off', // disable warning HTML in message
  fallbackLocale
})
