import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Result from '@/views/Result.vue'
import Video from '@/views/Video.vue'
import { i18n } from '@/i18n'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/video',
    name: 'Video',
    component: Video,
    meta: {
      title: 'meta.pageTitles.video'
    }
  },
  {
    path: '/result',
    name: 'Result',
    component: Result,
    meta: {
      title: 'meta.pageTitles.result'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'CatchAll',
    redirect: '/video'
  }
]

const siteTitle = 'Mataono'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = i18n.global.t(String(nearestWithTitle.meta.title)) + ' | ' + siteTitle
  } else {
    document.title = siteTitle
  }

  next()
})

router.afterEach((to, from) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window._paq === undefined || to.meta.analyticsIgnore) {
    return
  }

  const url = router.resolve(to.fullPath).href
  const referrerUrl = from && from.fullPath
    ? router.resolve(from.fullPath).href
    : undefined

  if (referrerUrl) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window._paq.push(['setReferrerUrl', referrerUrl])
  }
  if (url) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window._paq.push(['setCustomUrl', url])
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window._paq.push(['setDocumentTitle', document.title])
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window._paq.push(['trackPageView'])
})

export default router
