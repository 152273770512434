import {
  Run
} from '@/types/descript_emotion_demo_rest'
import {
  Message
} from '@/types/internal'

/**
 * Current State of the Vue App
 */
export interface State {
  immediateAlerts: Array<Message>,
  pendingAlerts: Array<Message>,
  currentRun: Run
}

export function getEmptyState (): State {
  return {
    immediateAlerts: [],
    pendingAlerts: [],
    currentRun: new Run()
  }
}
