import PromiseWorker from 'promise-worker'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Worker from 'worker-loader!./worker'

const promiseWorker = new PromiseWorker(new Worker())

const detectFaces = (message: any) => promiseWorker.postMessage({
  type: 'detectFaces',
  message
})
const destruct = () => promiseWorker.postMessage({
  type: 'destruct'
})
const initialize = () => promiseWorker.postMessage({
  type: 'initializeWeights'
})

export default {
  detectFaces,
  destruct,
  initialize
}
