import { version } from '../../package.json'

export const isProduction = process.env.NODE_ENV === 'production'

// regarding security https://stackoverflow.com/questions/39042799/cors-localhost-as-allowed-origin-in-production/39083377
export let url = ''
if (process.env.VUE_APP_MATAONO_ENDPOINT) {
  url = process.env.VUE_APP_MATAONO_ENDPOINT
} else {
  url = isProduction ? 'https://api-demo.mataono.app/' : 'http://localhost:8000/'
}

export const axiosConfiguration = {
  baseURL: url,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
}

export const matomoConfiguration = {
  host: 'https://stats.descript.de/',
  siteId: 152, // This is already a new one for demo.mataono.app in stats.descript.de
  trackerFileName: 'matomo',
  enableLinkTracking: true,
  disableCookies: true,
  requireConsent: false
}

export const sentryConfiguration = {
  // We have to disable autoSessionTracking here cause our self-hosted
  // Sentry instance running v9.x does not support session tracking.
  // We need to upgrade first. https://github.com/getsentry/sentry-javascript/issues/2859
  autoSessionTracking: false,
  dsn: isProduction
    ? 'https://bbf6f9133f6440b48283f9c8258e3981@sentry.descript.de/11'
    : 'https://c0031e45c884472caf197d7c5c04cf99@sentry.descript.de/12',
  release: 'vue-js-mataono-demo@' + version,
  ignoreErrors: [
    // Reason behind this ignore: https://stackoverflow.com/a/50387233
    'ResizeObserver loop limit exceeded',
    // From the following two Sentry can not extract stuff and it just leads to spam in our inboxes
    'Non-Error exception captured',
    'Non-Error promise rejection captured'
  ]
}

// I added URL demo.mataono.app to the key mataono.app in developer@descript.de Google
// reCAPTCHA admin console.
export const reCaptchaPublicSiteKey = isProduction ? '6LcM0TAdAAAAACN5CO3v47GznO2AUD-BN_WAzkuD' : '6Lc2gBYUAAAAAL6RCx7Nr6_ZM3K9xMPclEFrcC-Y'
