import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { store } from '@/store'
import { i18n } from '@/i18n'
import '@/assets/scss/style.scss'
import { isProduction, matomoConfiguration, sentryConfiguration } from '@/config/config'
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue'
// ignoring the Vue matomo import is necessary since matomo doesn't support typescript and don't plan
// on using it 'anytime soon' (https://issues.matomo.org/14472)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueMatomo from 'vue-matomo'
import * as Sentry from '@sentry/browser'
import VueApexCharts from 'vue3-apexcharts'
import 'vue-loading-overlay/dist/vue-loading.css'
import VueTour from 'v3-tour'
import { captureException } from '@/utils'

Sentry.init(sentryConfiguration)

window.addEventListener('error', captureException)
window.addEventListener('unhandledrejection', captureException)

require('v3-tour/dist/vue-tour.css')
const app = createApp(App).use(store).use(router).use(i18n).use(BootstrapIconsPlugin)
app.use(VueApexCharts)
app.use(VueTour)

if (isProduction) {
  app.use(VueMatomo, matomoConfiguration)
}

app.config.errorHandler = captureException

app.mount('#app')
