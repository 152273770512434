import router from '@/router'
import { store } from '@/store'
import { AlertType, CustomerColourSchema, ConsultantColourSchema } from '@/types/internal'
import {
  VideoPerson, VideoPersonRoles
} from '@/types/descript_emotion_demo_rest'
import * as Sentry from '@sentry/browser'
import { Severity } from '@sentry/browser'
import { i18n } from '@/i18n'

/**
 * General Error helper. This function will handle basic error Server responses like being not authorized or 500.
 * For non-basic errors a callback is invoked when defined. This way error handling can happen in the components a
 * request is made or store actions are dispatched.
 * @example
 * store.dispatch('...').then(undefined, // success handling is optional
 *   errorHelper((error) => {
 *       // error handling here
 *    }
 *  ))
 * http.post('...').then(
          () => {
            // success
          }, errorHelper((error) => {
            // error handling of errors which weren't caught by the helper
          }
          )).catch(
          (response) => {
            // catching of js-errors
          }
        )
 */
export function errorHelper (callback?: (error: any, nonFieldErrorsHandled?: boolean) => void, statusCodesToPassThrough?: Array<number>) {
  return (error: any) => {
    if (error.message === 'Network Error') {
      store.commit('addImmediateAlert', {
        msg: 'error.network_error',
        type: AlertType.Danger
      })
      return
    }

    if (error.response && !statusCodesToPassThrough?.includes(error.response.status)) {
      if (error.response.status === 401) {
        router.push('/')
        store.commit('addPendingAlert', {
          msg: 'error.not_authenticated',
          type: AlertType.Danger
        })
        return
      } else if (error.response.status === 403) {
        if (error.response.data.detail.startsWith('CSRF Failed')) {
          if (callback) {
            // if we trigger a CSRF error and have and error handler we can return here,
            // as we will handle a logout there
            callback(error)
            return
          }
          store.commit('addImmediateAlert', {
            msg: 'error.csrf_failed',
            type: AlertType.Danger,
            autoHide: false
          })
        } else {
          store.commit('addPendingAlert', {
            msg: 'error.permission_denied',
            type: AlertType.Danger
          })
        }
        return
      }
      if (error.response.status === 500) {
        store.commit('addImmediateAlert', {
          msg: 'error.server_error',
          type: AlertType.Danger
        })
        return
      }
    }

    let nonFieldErrorsHandled = false

    if (error.response && error.response.data) {
      if (typeof error.response.data === 'object' && 'non_field_errors' in error.response.data) {
        for (const nonFieldError of error.response.data.non_field_errors) {
          store.commit('addImmediateAlert', {
            msg: nonFieldError.toString(),
            type: AlertType.Danger
          })

          nonFieldErrorsHandled = true
        }
      }
    }

    // This is no ordinary error. Handle in origin, when callback is present.
    if (callback) {
      callback(error, nonFieldErrorsHandled)
    } else if (!nonFieldErrorsHandled) {
      if (error.message) {
        store.commit('addImmediateAlert', { msg: error.message, type: AlertType.Danger })
      } else {
        store.commit('addImmediateAlert', { msg: error.toString(), type: AlertType.Danger })
      }
      captureException(error)
    }
  }
}

export function convertToTimezone (date: Date, timeZone: string): Date {
  // Based on https://stackoverflow.com/a/54127122 and it may fail on
  // older browsers which do not support parameters for toLocaleString
  // method. Unfortunately there is no available standard library
  // method to do the job directly and therefore this workaround by
  // utilizing toLocaleString is used.
  return new Date(date.toLocaleString('en-US', { timeZone }))
}

export function datetimeLocalInputValue (date: Date): string {
  const year = date.getFullYear().toString().padStart(4, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hour = date.getHours().toString().padStart(2, '0')
  const minute = date.getMinutes().toString().padStart(2, '0')

  return `${year}-${month}-${day}T${hour}:${minute}`
}

/**
 * Returns a date string with a language sensitive
 * representation of the date
 * @param dateString
 * @param activeLocale
 */
export function getDate (dateString: string, activeLocale: string): string {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }

  return date.toLocaleDateString(activeLocale, options)
}

/**
 * Takes a formatted datestring and returns the actual weekday
 * @param dateString
 * @param activeLocale
 */
export function getDayOfWeek (dateString: string, activeLocale: string): string {
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const wochentag = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']

  const date = new Date(dateString.replace(/(.*)\.(.*)\.(.*)/, '$3-$2-$1'))
  return (activeLocale === 'de') ? wochentag[date.getDay()] : weekday[date.getDay()]
}

/**
 * Takes a formatted datestring and checks if it is today's date
 * @param dateString
 * @param activeLocale
 * @returns
 */
export function checkCurrentDate (dateString: string, activeLocale: string): boolean {
  const todaysDate = new Date().toLocaleDateString(activeLocale)
  const date = new Date(dateString.replace(/(.*)\.(.*)\.(.*)/, '$3-$2-$1')).toLocaleDateString(activeLocale)
  return todaysDate === date
}

/**
 * Takes a formatted datestring and returns only days and month
 * @param dateString
 * @param activeLocale
 * @returns
 */
export function returnDateWithoutYear (dateString: string, activeLocale: string): string {
  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit'
  }
  return new Date(dateString.replace(/(.*)\.(.*)\.(.*)/, '$3-$2-$1')).toLocaleDateString(activeLocale, options)
}

/**
 * Returns a time string with a language sensitive
 * representation of the time
 * @param dateString
 * @param activeLocale
 */
export function getTime (dateString: string, activeLocale: string): string {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric'
  }

  return date.toLocaleTimeString(activeLocale, options)
}

export function compareStrings (s1: string, s2: string): number {
  const s1LowerCased = s1.toLowerCase()
  const s2LowerCased = s2.toLowerCase()

  return s1LowerCased.localeCompare(s2LowerCased, undefined, {
    numeric: true,
    sensitivity: 'base'
  })
}

export function captureException (event: ErrorEvent | PromiseRejectionEvent | unknown): void {
  console.log(event)
  Sentry.setExtra('username', localStorage.getItem('username'))
  Sentry.captureException(event)
}

export function captureExceptionAsInfo (event: ErrorEvent | PromiseRejectionEvent | unknown): void {
  Sentry.setExtra('username', localStorage.getItem('username'))
  Sentry.captureException(event)
  Sentry.withScope(function (scope) {
    scope.setLevel(Severity.Info)
    Sentry.captureException(event)
  })
}

export function getPrettyTime (seconds: number): string {
  const secondsRounded = Math.round(seconds)
  const prettySeconds = secondsRounded % 60
  const prettySecondsString = prettySeconds > 9 ? `${prettySeconds}` : `0${prettySeconds}`
  return i18n.global.t('base.pretty_time', { time: `${Math.floor(secondsRounded / 60)}:${prettySecondsString}` })
}

export function getLocalizedNumber (value: number | null | undefined, activeLocale: string, maximumFractionDigits: number | undefined): string {
  if (value === undefined) {
    return '-'
  }

  if (value === null) {
    return '-'
  }

  if (maximumFractionDigits === undefined) {
    maximumFractionDigits = 1
  }

  return value.toLocaleString(activeLocale, { maximumFractionDigits })
}

export function getLocalizedDateTime (value: Date | null | undefined, activeLocale: string): string {
  if (value === undefined) {
    return '-'
  }

  if (value === null) {
    return '-'
  }

  return value.toLocaleString(activeLocale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  })
}

export function getPersonName (personId: number, personList: Array<VideoPerson>): string {
  let name = ''
  for (const person of personList) {
    if (person.id === personId) {
      name = person.name
      break
    }
  }
  return name
}

export function getColorClass (mood: number | undefined | null): string {
  if (mood === undefined) {
    return ''
  }

  if (mood === null) {
    return ''
  }

  if (mood >= 70) {
    return 'success'
  }

  if (mood < 30) {
    return 'danger'
  }

  return ''
}

export function getRoleBasedColourSchema (videoPersonList: Array<VideoPerson>): Array<string> {
  let customerIndex = 0
  let consultantIndex = 0

  const videoPersonColourList: Array<string> = []

  videoPersonList.forEach((person: VideoPerson) => {
    if (person.role === VideoPersonRoles.Customer) {
      videoPersonColourList.push(CustomerColourSchema[customerIndex])
      customerIndex = (customerIndex + 1) % (CustomerColourSchema.length)
    } else if (person.role === VideoPersonRoles.Consultant) {
      videoPersonColourList.push(ConsultantColourSchema[consultantIndex])
      consultantIndex = (consultantIndex + 1) % (ConsultantColourSchema.length)
    }
  })
  return videoPersonColourList
}
