import { State } from '@/store/state'
import { AlertType } from '@/types/internal'
import {
  Run
} from '@/types/descript_emotion_demo_rest'

export const mutations = {
  /**
   * adds a Message Alert which will be displayed at the current view
   *
   * @example
   * this.$store.commit('addImmediateAlert', { msg: MESSAGE, type: AlertType.Danger })
   */
  addImmediateAlert (state: State, payload: { msg: string; type?: AlertType; autoHide?: boolean }): void {
    if (payload.type === undefined) {
      payload.type = AlertType.Info
    }
    if (payload.autoHide === undefined) {
      payload.autoHide = false
    }

    state.immediateAlerts.push({ msg: payload.msg, type: payload.type, autoHide: payload.autoHide })
  },
  clearImmediateAlerts (state: State): void {
    // splice with these parameters is the performant way to empty an array
    state.immediateAlerts.splice(0, state.immediateAlerts.length)
  },
  clearAndDismissImmediateAlerts (state: State): void {
    // splice with these parameters is the performant way to empty an array
    state.immediateAlerts.splice(0, state.immediateAlerts.length)
  },
  /**
   * adds an Alert for the next visible view. The function will handle itself, that every unique alert message
   * will only be displayed once
   *
   * @example
   * this.$store.commit('addPendingAlert', { msg: MESSAGE, type: AlertType.Danger })
   */
  addPendingAlert (state: State, payload: { msg: string; type?: AlertType; autoHide?: boolean }): void {
    if (payload.type === undefined) {
      payload.type = AlertType.Info
    }
    if (payload.autoHide === undefined) {
      payload.autoHide = false
    }

    // A pending alert must only be added if there is not already an exact
    // same message in the list of pending alerts. Adding the same alert could
    // for example happen when multiple requests to the REST api are done, and
    // they fail with a non-authenticated error.

    let alreadyThere = false
    for (const pendingAlert of state.pendingAlerts) {
      if (pendingAlert.msg === payload.msg && pendingAlert.type === payload.type) {
        alreadyThere = true
      }
    }

    if (!alreadyThere) {
      state.pendingAlerts.push({ msg: payload.msg, type: payload.type, autoHide: payload.autoHide })
    }
  },
  clearPendingAlerts (state: State): void {
    // splice with these parameters is the performant way to empty an array
    state.pendingAlerts.splice(0, state.pendingAlerts.length)
  },
  setCurrentRun (state: State, payload: Run): void {
    state.currentRun = payload

    if (payload.id !== 0) {
      localStorage.setItem('currentRun', JSON.stringify(payload))
    } else {
      localStorage.removeItem('currentRun')
    }
  }
}
