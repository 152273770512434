/* eslint camelcase: "off" */

export interface RunFromRest {
  id: number,
  email: string,
  created: string
}

export class Run {
  id: number
  created: string
  emailSet: boolean

  constructor (restRun?: RunFromRest) {
    if (!restRun) {
      restRun = {
        id: 0,
        email: '',
        created: ''
      }
    }

    this.id = restRun.id
    this.created = restRun.created
    this.emailSet = false
  }
}

export interface RunEmotionAnalysisData {
  continuous_mood_graphs: Record<number, Array<[number, number | null]>>,
  average_moods: Record<number, number>,
  happy_times: Record<number, number>,
  sad_times: Record<number, number>,
  total_time: number,
  standard_deviation: Record<number, number>,
  global_average: Array<[number, number | null]>
}

export function getPlaceholderEmotionAnalysisData (): RunEmotionAnalysisData {
  return {
    continuous_mood_graphs: {},
    average_moods: {},
    happy_times: {},
    sad_times: {},
    total_time: 0,
    standard_deviation: {},
    global_average: []
  }
}

// These must reflect the VIDEO_PERSON_ROLE_* constants in descript_emotion_demo.
export enum VideoPersonRoles {
  None,
  Customer,
  Consultant
}

export interface VideoPerson {
  id: number,
  run: number,
  name: string,
  role: VideoPersonRoles
}

export function getPlaceholderVideoPerson (): VideoPerson {
  return {
    id: 0,
    run: 0,
    name: '',
    role: VideoPersonRoles.None
  }
}

export interface MoodEventData {
  timestamp: string,
  name: string,
  emotions: Array<[number, number, number, number, number, number, number]>
}

// These must reflect the EVENT_TYPE_* constants in descript_emotion_demo.
export enum EventTypes {
  None,
  ErrorHappened,
  LoadingFinished,
  VideoStarted,
  VideoFinished,
  ReachedResultView
}

export interface Event {
  id: number,
  run: number,
  type: EventTypes,
  details: string,
  created: string
}
