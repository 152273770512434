import { ActionContext } from 'vuex'
import { State } from '@/store/state'
import http from '@/http-common'
import {
  Event,
  EventTypes,
  MoodEventData,
  Run,
  RunEmotionAnalysisData,
  RunFromRest,
  VideoPerson
} from '@/types/descript_emotion_demo_rest'

function ensureValidRun (runFromRest: RunFromRest): Run {
  return new Run(runFromRest)
}

export const actions = {
  async addRun (): Promise<Run> {
    const response = await http.post('emotion/run/', JSON.stringify({}))
    return ensureValidRun(response.data)
  },
  async ensureCurrentRun (context: ActionContext<State, State>, lookIntoLocalStorage: boolean): Promise<void> {
    let runToReturn

    if (context.state.currentRun.id === 0) {
      let currentRunFromLocalStorage = null

      if (lookIntoLocalStorage) {
        currentRunFromLocalStorage = localStorage.getItem('currentRun')
      }

      if (currentRunFromLocalStorage) {
        runToReturn = JSON.parse(currentRunFromLocalStorage)
      } else {
        runToReturn = await context.dispatch('addRun')
      }

      context.commit('setCurrentRun', runToReturn)
    }
  },
  async removeCurrentRun (context: ActionContext<State, State>): Promise<void> {
    context.commit('setCurrentRun', new Run())
  },
  async createMoodEventForRunId (context: ActionContext<State, State>, payload: { runId: number, moodEventData: MoodEventData }): Promise<void> {
    await http.post(`emotion/run/${payload.runId}/create_mood_event/`, payload.moodEventData)
  },
  async setCreated (
    context: ActionContext<State, State>, created: string
  ): Promise<void> {
    if (context.state.currentRun.id !== 0) {
      await http.patch(`emotion/run/${context.state.currentRun.id}/`, JSON.stringify({ created }))
      context.state.currentRun.created = created
      // mutation setCurrentRun mainly to trigger also writing into local storage.
      context.commit('setCurrentRun', context.state.currentRun)
    }
  },
  async setEmail (
    context: ActionContext<State, State>, email: string
  ): Promise<void> {
    if (context.state.currentRun.id !== 0) {
      await http.patch(`emotion/run/${context.state.currentRun.id}/`, JSON.stringify({ email }))
      context.state.currentRun.emailSet = true
      // mutation setCurrentRun mainly to trigger also writing into local storage.
      context.commit('setCurrentRun', context.state.currentRun)
    }
  },
  async getVideoPersonListByRunId (
    context: ActionContext<State, State>, runId: number
  ): Promise<Array<VideoPerson>> {
    const params = {
      run: runId.toString()
    }
    const response = await http.get('emotion/videoperson/', { params })
    return response.data
  },
  /**
   * Retrieves emotion analysis data which have been calculated by the backend.
   */
  async emotionAnalysisData (
    context: ActionContext<State, State>, payload: { runId: number, rollingWindowSize: number }
  ): Promise<RunEmotionAnalysisData> {
    const params = {
      rolling_window_size: payload.rollingWindowSize
    }
    const response = await http.get(`emotion/run/${payload.runId}/emotion_analysis_data/`, { params })
    return response.data
  },
  async addEvent (
    context: ActionContext<State, State>,
    payload: { run: number, type: EventTypes, details: string, created: string }
  ): Promise<Event> {
    const response = await http.post('emotion/event/', JSON.stringify(payload))
    return response.data
  }
}
