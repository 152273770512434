/**
 * Types of possible Alerts
 * @enum {string}
 */
export enum AlertType {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark'
}

export interface Message {
  msg: string,
  type: AlertType,
  autoHide: boolean,
}

export const CustomerColourSchema = [
  '#44CA67',
  '#FCD303',
  '#9ABAD6',
  '#6610F2',
  '#0DCAF0',
  '#CCC6A5',
  '#FD7E14'
]

export const ConsultantColourSchema = [
  '#0D2129',
  '#0D6EFD',
  '#D63384'
]
